const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/admin/dashboard",
        target: "admin.dashboard",
        svgIcon: "other/svg/vuesax-bold-graph.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Partai Politik",
    route: "/admin/politic",
    pages: [
      {
        heading: "Partai",
        route: "/admin/politic/parties",
        target: "admin.politic.parties",
        svgIcon: "other/svg/vuesax-bold-flag.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "Kader",
        route: "/admin/politic/kader",
        svgIcon: "other/svg/vuesax-bold-user-tag-2.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Daftar",
            route: "/admin/politic/kader/list",
            target: "admin.politic.kader.list",
          },
          {
            heading: "Kategori",
            route: "/admin/politic/kader/category",
            target: "admin.politic.kader.category",
          },
        ],
      },
    ],
  },
  {
    heading: "Vote",
    pages: [
      {
        heading: "Zona",
        route: "/admin/vote/zonings",
        target: "admin.vote.zonings",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "KTAnisasi",
        route: "/admin/vote/ktanisasi",
        target: "admin.vote.ktanisasi",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "KTAnisasi Summary",
        route: "/admin/vote/ktanisasi-sums",
        target: "admin.vote.ktanisasi-sums",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Master",
    pages: [
      {
        sectionTitle: "Wilayah",
        route: "/admin/master/territory",
        svgIcon: "other/svg/vuesax-bold-user-tag-2.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Daftar",
            route: "/admin/master/territory/list",
            target: "admin.master.territory.list",
          },
          {
            heading: "Kategori",
            route: "/admin/master/territory/levels",
            target: "admin.master.territory.levels",
          },
        ],
      },
      {
        heading: "Attribut",
        route: "/admin/master/attributions",
        target: "admin.master.attributions",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Posisi",
        route: "/admin/master/position",
        target: "admin.master.position",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Account",
    pages: [
      {
        heading: "Konfirmasi",
        route: "/admin/account/confirm",
        target: "admin.account.confirm",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Pengguna",
        route: "/admin/account/users",
        target: "admin.account.users",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
